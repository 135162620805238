import React, { useState } from 'react';
import './case-study-search.css';
import close from '../../../assets/icons/icons8-cancel-30.png';

const CaseStudySearch: React.FC<{ onSearch: (query: string) => void }> = ({ onSearch }) => {
    let [query, setQuery] = useState<string>('');

    const handleSearch = () => {
        if (query) {
            onSearch(query);
        }
    };

    const handleCancel = () => {
        setQuery('');
        onSearch('');
    };

    console.log(query);

    return (
        <div className="case-study-search-wrapper">
            <div className='case-study-search-controls'>
                <input
                    type="text"
                    placeholder="Search case studies..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="case-study-search-input blog-search-input"

                />
                {query && <span onClick={handleCancel}><img src={close} /></span>}
            </div>

            <button onClick={handleSearch} className="case-study-search-button blog-search-button">
                Search
            </button>

        </div>
    );
};

export default CaseStudySearch;
