import React from 'react';
import CaseStudyTile from '../caseStudyTile';
import './case-study-page-tiles.css';
import { ResourceData } from '../../../types/caseStudyTypes';

interface CaseStudyPageTilesProps {
    posts: {
        resourceData: ResourceData[];
    };
}

const CaseStudyPageTiles: React.FC<CaseStudyPageTilesProps> = ({ posts }) => {
    return (
        <section className="case-study-page-tiles-section">
            <div className="container">
                <div className="case-study-page-tiles-wrapper">
                    {posts?.resourceData?.map((item: ResourceData, i: number) => (
                        <CaseStudyTile
                            key={`case-study-${i}`}
                            to={item.id}
                            title={item.caseStudyTitle}
                            image={item.companyLogo}
                            author={item.author.name}
                            headline={item.title}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CaseStudyPageTiles;
