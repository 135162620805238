import React, { useState } from 'react';
import CtaBlock from '../components/components-group-one/CtaBlock';
import HtmlHead from '../components/html-head';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { graphql } from 'gatsby';
import { PageData } from '../types';
import caseStudyData from '../data/casestudyData';
import CaseStudyPageTiles from '../components/case-study-listing/caseStudyPageTiles';
import CaseStudyHero from '../components/resources-components/case-study-hero';

const CaseStudyListing: React.FC<PageData> = (resp) => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;
    const [filteredCaseStudies, setFilteredCaseStudies] = useState(caseStudyData.resourceData);

    const handleSearch = (query: string) => {
        if (query === '') {
            setFilteredCaseStudies(caseStudyData.resourceData);
        } else {
            const filtered = caseStudyData.resourceData.filter(caseStudy =>
                caseStudy.caseStudyTitle.toLowerCase().includes(query.toLowerCase()) ||
                caseStudy.title.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredCaseStudies(filtered);
        }
    };

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <div className="blog-page-bg">
                <CaseStudyHero onSearch={handleSearch} />
                <CaseStudyPageTiles posts={{ resourceData: filteredCaseStudies }} />
                <Footer />
            </div>
        </>
    );
};

export default CaseStudyListing;

export const query = graphql`
    query CaseStudyQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/case-studies" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;
